import logo from './logo.jpg';
import './App.css';

function App() {
  return (
        <div className="App">
          <article className="container-fluid text-center my-auto">
          <img src={logo} alt="Immobilgio"  className = "Applogo img-fluid mt-4" ></img>
          <h1>Torneremo presto!</h1>
            <div className="container text-center my-auto">
              <p>Ci scusiamo per l'inconveniente ma stiamo rinnovando il sito. Per qualsiasi necessità potete sempre <a href = "mailto:immobilgio@gmail.com">contattarci</a>, altrimenti torneremo presto online.</p>
              <p>&mdash; Immobilgio</p>
            </div>
          </article>
          <footer className=" container-fluid bg-body-tertiary text-center py-3 fixed-bottom">
            <div className="container">
                <p>&copy; 2024 Immobilgio. Tutti i diritti riservati.</p>
                <p>Indirizzo: 32100 Belluno</p>
                <p>Email: immobilgio@gmail.com</p>
                <p>Telefono: +39 3496940409</p>
            </div>
          </footer>
        </div>
      );
    }

export default App;
